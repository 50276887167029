import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jobs: []
  },
  getters: {},
  mutations: {
    changeJobs(state, val) {
      state.jobs = val
    }
  },
  actions: {
    setJobs(context, val) {
      context.commit('changeJobs', val)
    }
  },
  modules: {}
})
