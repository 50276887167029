<template>
  <div class="bottom_wrap">
    <div class="yqlj_box">
      <div class="yqlj">
        <span>友情链接</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <a target="_blank" :href="item.link">{{ item.label }}</a>
          </el-option>
        </el-select>
        <el-select v-model="value2" placeholder="请选择">
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <a target="_blank" :href="item.link">{{ item.label }}</a>
          </el-option>
        </el-select>
        <el-select v-model="value3" placeholder="请选择">
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <a target="_blank" :href="item.link">{{ item.label }}</a>
          </el-option>
        </el-select>
        <el-select v-model="value4" placeholder="请选择">
          <el-option
            v-for="item in options4"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <a target="_blank" :href="item.link">{{ item.label }}</a>
          </el-option>
        </el-select>
      </div>
    </div>
    <el-row class="b_box" type="flex" justify="space-between">
      <el-col :span="10" :xs="24">
        <div>
          <p>版权所有：株洲市总工会</p>
          <p>电 话：0731-28686186</p>
          <p>邮 箱：zhzhgy@sina.com</p>
          <p>地 址：湖南省株洲市天元区天伦西路86号</p>
          <p>湘公网安备 43021102000235号 湘ICP备13012697号-2</p>
        </div>
      </el-col>
      <el-col class="code_wrap" :span="11" :xs="24">
        <div class="code_box">
          <div>
            <img src="../assets/images/code_01.png" />
            <p>株洲工会微信</p>
          </div>
          <div>
            <img src="../assets/images/code_02.png" />
            <p>株洲工会抖音</p>
          </div>
          <div class="big">
            <img src="../assets/images/code_03.jpg" />
            <p>株洲云工会应用平台移动端</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  props: {
    msg: String
  },
  data() {
    return {
      value: '1',
      value2: '1',
      value3: '1',
      value4: '1',
      options: [
        {
          value: '1',
          label: '中华全国总工会',
          link: 'http://www.acftu.org/'
        },
        {
          value: '2',
          label: '湖南省总工会',
          link: 'https://www.hnghw.com/#/home'
        },
        {
          value: '3',
          label: '株洲市总工会',
          link: 'https://www.zzghw.com/'
        }
      ],
      options2: [
        {
          value: '1',
          label: '株洲市政协网',
          link: 'http://www.hnzzzx.gov.cn/'
        },
        {
          value: '2',
          label: '株洲市政府',
          link: 'http://www.zhuzhou.gov.cn/'
        },
        {
          value: '3',
          label: '株洲市民政局',
          link: 'http://mzj.zhuzhou.gov.cn/'
        },
        {
          value: '4',
          label: '市司法局',
          link: 'http://sfj.zhuzhou.gov.cn/'
        },
        {
          value: '5',
          label: '市城管局',
          link: 'http://cgj.zhuzhou.gov.cn/'
        },
        {
          value: '6',
          label: '株洲市文化局',
          link: 'http://wtgxj.zhuzhou.gov.cn/'
        },
        {
          value: '7',
          label: '株洲公路局',
          link: 'http://glj.zhuzhou.gov.cn/'
        },
        {
          value: '8',
          label: '株洲市烟草局',
          link: 'http://www.hntobacco.gov.cn:86/'
        },
        {
          value: '9',
          label: '株洲市公安局',
          link: 'http://www.hnzzjj.gov.cn/wscgs/zzjjw/index.aspx'
        },
        {
          value: '10',
          label: '株洲市财政局',
          link: 'http://czj.zhuzhou.gov.cn/'
        },
        {
          value: '11',
          label: '株洲市人社局',
          link: 'http://rsj.zhuzhou.gov.cn/'
        },
        {
          value: '12',
          label: '株洲市旅游局',
          link: 'http://lyws.zhuzhou.gov.cn/'
        },
        {
          value: '13',
          label: '中国文明网',
          link: 'http://www.wenming.cn/'
        }
      ],
      options3: [
        {
          value: '1',
          label: '长沙市总工会',
          link: 'http://zgh.changsha.gov.cn/'
        },
        {
          value: '2',
          label: '衡阳市总工会',
          link: 'http://www.hyzgh.org.cn/'
        },
        {
          value: '3',
          label: '岳阳市总工会',
          link: 'http://yyghw.com/'
        }
      ],
      options4: [
        {
          value: '1',
          label: '点击株洲',
          link: ''
        },
        {
          value: '2',
          label: '醴陵市总工会',
          link: 'http://llghw.com/'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
.yqlj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yqlj_box {
  width: $content-width;
  padding: 40px 0 0;
  margin: 0 auto;
}
.bottom_wrap {
  background-color: #cacaca;
  // width: fit-content;
  .b_box {
    width: $content-width;
    padding: 40px 0;
    margin: 0 auto;
    text-align: left;
    p {
      line-height: 32px;
      font-size: 14px;
      color: #666;
    }
    .code_box {
      display: flex;
      justify-content: flex-end;
      div {
        text-align: center;
        // &.big {
        width: 170px;
        // }
      }
      img {
        width: 100px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .yqlj_box {
    display: none;
  }
  .bottom_wrap .b_box {
    padding: 20px 10px;
    width: 100%;
    p {
      line-height: 22px;
      font-size: 12px;
    }
    .code_wrap {
      display: none;
    }
  }
}
</style>
