import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/Info.vue')
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('../views/Work.vue')
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/textView',
    name: 'textView',
    component: () => import('../views/TextView.vue')
  },
  {
    path: '/craftsman',
    name: 'craftsman',
    component: () => import('../views/Craftsman.vue')
  },
  {
    path: '/synopsis',
    name: 'Synopsis',
    component: () => import('../views/Synopsis.vue')
  }
]

const router = new VueRouter({
  routes
})

const changePush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch((err) => err)
}

export default router
