import request from './axios'

export function getBanners(params) {
  return request({
    url: 'index/banners',
    method: 'get',
    params
  })
}

export function getLeaders(params) {
  return request({
    url: 'index/leaders',
    method: 'get',
    params
  })
}

export function getIntro(params) {
  return request({
    url: 'index/intro',
    method: 'get',
    params
  })
}

export function getJobs(params) {
  return request({
    url: 'index/jobs',
    method: 'get',
    params
  })
}
export function getNews(params) {
  return request({
    url: 'index/newscat',
    method: 'get',
    params
  })
}
export function getNewsList(params) {
  return request({
    url: 'index/newslist',
    method: 'get',
    params
  })
}
export function getNewsDetail(params) {
  return request({
    url: 'index/newsdetail',
    method: 'get',
    params
  })
}
